// extracted by mini-css-extract-plugin
export var amberBg = "index-module--amber-bg--CwRuA";
export var amberBgLeft = "index-module--amber-bg-left--05NvP";
export var amberBgRight = "index-module--amber-bg-right--1shCN";
export var amberBlock = "index-module--amber-block--64DAL";
export var amberCan = "index-module--amber-can--+8RDW index-module--can--nqAo-";
export var beerContainer = "index-module--beer-container--JvnYH";
export var beerContent = "index-module--beer-content--ug5Ra";
export var beerContent2 = "index-module--beer-content2--W-c7g";
export var beerDescription = "index-module--beer-description--xpCh6";
export var beerTitle = "index-module--beer-title--wKHVi";
export var block = "index-module--block--Ap3N1";
export var can = "index-module--can--nqAo-";
export var caramel = "index-module--caramel--Gry6W";
export var caramel2 = "index-module--caramel2--m4eJP";
export var coffee = "index-module--coffee--X0EC0";
export var container = "index-module--container--2GuoF";
export var hopIcon = "index-module--hop-icon--7lsGu";
export var lemon = "index-module--lemon--lFnSU";
export var mainBlock = "index-module--main-block--1UHKU index-module--block--Ap3N1";
export var porterBg = "index-module--porter-bg--xKR6K";
export var porterBgLeft = "index-module--porter-bg-left--T78fo";
export var porterBgRight = "index-module--porter-bg-right--Ay9yR";
export var porterBlock = "index-module--porter-block--udI81";
export var porterBlock2 = "index-module--porter-block2--fv4Jx index-module--porter-block--udI81";
export var porterCan = "index-module--porter-can--mFNvt index-module--can--nqAo-";
export var productScores = "index-module--productScores--lyZFT";
export var secondaryBlock = "index-module--secondary-block--OnX9m index-module--block--Ap3N1";
export var secondaryContent = "index-module--secondary-content--ZhM7z";
export var seltzerBg = "index-module--seltzer-bg--AJGtX";
export var seltzerBgLeft = "index-module--seltzer-bg-left--W7xmd";
export var seltzerBgRight = "index-module--seltzer-bg-right--+90e-";
export var seltzerBlock = "index-module--seltzer-block--Em8xN";
export var seltzerCan = "index-module--seltzer-can--Mr0Mi index-module--can--nqAo-";
export var tangerine = "index-module--tangerine--Yjwva";
export var title = "index-module--title--Vu6RA";
export var watermelon = "index-module--watermelon--mQbNI";