// extracted by mini-css-extract-plugin
export var bar = "product-scores-module--bar---mwjH";
export var fill = "product-scores-module--fill--2+uzn";
export var header = "product-scores-module--header--HUF3a";
export var high = "product-scores-module--high--vDp2B product-scores-module--fill--2+uzn";
export var low = "product-scores-module--low--yHXh2 product-scores-module--fill--2+uzn";
export var medium = "product-scores-module--medium--WdK0G product-scores-module--fill--2+uzn";
export var mediumHigh = "product-scores-module--medium-high--4KdHB product-scores-module--fill--2+uzn";
export var mediumLow = "product-scores-module--medium-low--FhI0B product-scores-module--fill--2+uzn";
export var rail = "product-scores-module--rail--pu4Z1";
export var wrapper = "product-scores-module--wrapper--T+8nM";
export var wrapperOffscreen = "product-scores-module--wrapper-offscreen--F6i+T product-scores-module--wrapper--T+8nM";