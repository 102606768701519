// extracted by mini-css-extract-plugin
export var branding = "footer-module--branding--HOKj9";
export var container = "footer-module--container--azqjV";
export var content = "footer-module--content--DqaP1";
export var hashtag = "footer-module--hashtag---BmPT";
export var icons = "footer-module--icons--3bXex";
export var image = "footer-module--image--NMJTR";
export var legal = "footer-module--legal--fzete";
export var rights = "footer-module--rights--BdHaZ";
export var socialContainer = "footer-module--social-container--JOufV";
export var stamp = "footer-module--stamp--lE1e0";